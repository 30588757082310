import { graphql } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import Pagination from '../components/Pagination';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  Posts,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteHeaderStyles,
  SiteHeaderBackground,
} from '../styles/shared';
import config from '../website-config';
import { PageContext } from './post';

export interface IndexProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: GatsbyImage;
      };
    };
    header: {
      childImageSharp: {
        fixed: GatsbyImage;
      };
    };
    allMdx: {
      edges: Array<{
        node: PageContext;
      }>;
    };
    site: {
      buildTime: string;
    };
  };
}

export const Divider = ({ children, linewidth }) => {
  return (
    <div
      className="divider-container"
      style={{ display: 'flex', alignItems: 'center', padding: '1em 0em 1em 0em' }}
    >
      <div className="divider-border" style={{ width: linewidth }} />
      <span className="divider-content">{children}</span>
      <div className="divider-border" style={{ width: linewidth }} />
    </div>
  );
};

const IndexPage: React.FC<IndexProps> = props => {
  const { width, height } = props.data.header.childImageSharp.gatsbyImageData;

  const jsonld_index = {
    '@context': 'http://schema.org',
    '@graph': [
      {
        '@type': 'WebPage',
        url: config.siteUrl,
        inLanguage: config.lang,
        description: 'The index page of machinelearningcompass.com',
        name: 'Index',
        datePublished: '2021-01-22T20:06:55.979Z', //ggf. ändern
        dateModified: props.data.site.buildTime,
        image: {
          '@type': 'ImageObject',
          url: getSrc(config.siteUrl + props.data.logo.childImageSharp.gatsbyImageData),
          width: 1920,
          height: 472,
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://machinelearningcompass.com/#website',
        url: config.siteUrl,
        name: config.title,
        description: config.description,
        headline: config.description,
        inLanguage: config.lang,
        datePublished: '2021-01-22T20:06:55.979Z', //ggf. ändern
        dateModified: props.data.site.buildTime,
        author: {
          '@type': 'Person',
          name: 'Lari Giba',
        },
        copyrightHolder: {
          '@type': 'Person',
          name: 'Lari Giba',
        },
        copyrightYear: '2021',
        creator: {
          '@type': 'Person',
          name: 'Lari Giba',
        },
        publisher: {
          '@type': 'Person',
          name: 'Lari Giba',
        },
        logo: {
          '@id': 'https://machinelearningcompass.com/#logo',
          '@type': 'ImageObject',
          url: getSrc(config.siteUrl + props.data.logo.childImageSharp.gatsbyImageData),
        },
      },
    ],
  };

  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${getSrc(props.data.header.childImageSharp.gatsbyImageData)}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${getSrc(props.data.header.childImageSharp.gatsbyImageData)}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />

        <script type="application/ld+json">{JSON.stringify(jsonld_index)}</script>
      </Helmet>
      <Wrapper>
        <div
          css={[IndexBanner, outer, SiteHeader, SiteHeaderStyles]}
          className="site-header-background"
          style={{
            backgroundImage: `url('${getSrc(props.data.header.childImageSharp.gatsbyImageData)}')`,
          }}
        >
          <div css={inner}>
            <SiteNav isHome />
            <SiteHeaderContent className="site-header-content">
              {/*<SiteTitle className="site-title">
                {props.data.logo ? (
                  <img
                    style={{ maxHeight: '180px' }}
                    src={props.data.logo.childImageSharp.fixed.src}
                    alt={config.title}
                  />
                ) : (
                  config.title
                )}
              </SiteTitle>
              <SiteDescription>{config.description}</SiteDescription>*/}
            </SiteHeaderContent>
          </div>
        </div>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={[inner, Posts]} style={{ maxWidth: '2080px' }}>
            {/*
            <Divider linewidth="250%">Start here</Divider> --hide this section for now, add back when there are more posts

            --featured posts (for new readers), should rarely change
            <div css={[PostFeed]}>
              {props.data.allMdx.edges.map((post, index) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true || 
                    process.env.NODE_ENV !== 'production') && (post.node.frontmatter.showOnHome == true) && (
                    <PostCard key={post.node.fields.slug} post={post.node} large={post.node.frontmatter.uniquePostId == 0} />
                  )
                );
              })}
            </div>
            */}

            {/*<Divider linewidth="80%">Or expand your knowledge with these</Divider> hide divider for now*/}

            <Divider linewidth="80%">Read the newest posts here</Divider>
            {/* new or interesting posts, will change frequently */}
            <div css={[PostFeed]}>
              {props.data.allMdx.edges
                .filter(
                  post =>
                    process.env.NODE_ENV == 'development' || post.node.frontmatter.draft === false,
                )
                .map((post, index) => {
                  // filter out drafts in production
                  return (
                    <PostCard
                      key={post.node.fields.slug}
                      post={post.node}
                      large={post.node.frontmatter.uniquePostId == 0}
                    />
                  );
                })}
            </div>
          </div>
        </main>
        {props.children}
        {props.pageContext.numPages > 1 && (
          <Pagination
            currentPage={props.pageContext.currentPage}
            numPages={props.pageContext.numPages}
          />
        )}
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    logo: file(relativePath: { eq: "img/compasslogo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, layout: FIXED, placeholder: BLURRED)
      }
    }
    header: file(relativePath: { eq: "img/mlcbanner.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 100, layout: FIXED, placeholder: BLURRED)
      }
    }
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          frontmatter {
            title
            date
            dateModified
            tags
            draft
            excerpt
            uniquePostId
            timeToRead
            tutorialId
            tutorialEntryIdx
            dependencies
            proficiencyLevel
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;

const IndexBanner = css`
  @media (max-width: 500px) {
    margin-top: -20px;
  }
`;

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
      width: 50%;
      height: 50%;
      clear: both;
      float: left;
    }

    .post-card-large .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large .post-card-content-link {
      padding: 0 20px 0 40px;
    }

    .post-card-large .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

export default IndexPage;
